/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Navbar from "./navbar"
import Footer from "./footer"

const Layout = ({ children }) => {
  const { site } = useStaticQuery(
    graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            menu {
              main {
                name
                path
                mobileOnly
              }
              social {
                name
                path
                external
                icon {
                  type
                  icon
                }
              }
            }
          }
        }
      }
    `
  )

  const { title, menu } = site.siteMetadata
  return (
    <>
      <Navbar siteTitle={title} menu={menu.main} social={menu.social} />
      <main className="py-24">{children}</main>
      <Footer>
        <p>© {new Date().getFullYear()}, randylough.com</p>
      </Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
