/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function isPostType(path) {
  if (path.startsWith("/blog/") || path.startsWith("/projects/")) return true
  return false
}
const schemaOrgJSONLD = ld => {
  const {
    path,
    siteUrl,
    pageTitle,
    siteTitle,
    image,
    description,
    datePublished,
    logo,
    author,
  } = ld

  const pageUrl = `${siteUrl}${path}`
  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: pageUrl,
      name: pageTitle,
      alternateName: siteTitle,
    },
  ]

  return isPostType(path)
    ? [
        ...schemaOrgJSONLD,
        {
          "@context": siteUrl,
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": pageUrl,
                name: pageTitle,
                image,
              },
            },
          ],
        },
        {
          "@context": siteUrl,
          "@type": "BlogPosting",
          url: pageUrl,
          name: pageTitle,
          alternateName: siteTitle,
          headline: pageTitle,
          image: {
            "@type": "ImageObject",
            url: image,
          },
          description,
          author: {
            "@type": "Person",
            name: author,
          },
          publisher: {
            "@type": "Organization",
            url: siteUrl,
            logo: logo,
            name: author,
          },
          mainEntityOfPage: {
            "@type": "WebSite",
            "@id": siteUrl,
          },
          datePublished,
        },
      ]
    : schemaOrgJSONLD
}

const SEO = props => {
  let {
    description,
    lang,
    meta,
    keywords,
    title,
    path,
    datePublished,
    image,
  } = props

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            logo
          }
        }
      }
    `
  )
  const {
    author,
    title: SiteTitle,
    description: siteDescription,
    logo,
    siteUrl,
  } = site.siteMetadata

  const metaDescription = description || siteDescription

  if (image && typeof image === "object") {
    image = new URL(image.publicURL, siteUrl)
  } else {
    image = new URL(image, siteUrl)
  }

  const jsonLD = schemaOrgJSONLD({
    path,
    siteUrl: siteUrl,
    pageTitle: title,
    siteTitle: SiteTitle,
    image,
    description: metaDescription,
    datePublished,
    logo: logo,
    author: author,
  })

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${SiteTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: author,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        image
          ? {
              property: `og:image`,
              content: image,
            }
          : {},
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
      <meta
        name="google-site-verification"
        content="kGXEuT2JTErsK0esVEQ_HyU44HL8W0vRABkw7DDkpCs"
      />
      <link rel="canonical" href={new URL(path, siteUrl)} />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
