import React from "react"
import PropTypes from "prop-types"

import { ExpandedMenu, MobileMenu, SocialMenu } from "./Menu"

import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "../ui/Image"

export default function Navbar(props) {
  const { menu, social, siteTitle } = props

  const mark = useStaticQuery(graphql`
    query MarkQuery {
      file(relativePath: { eq: "emoji.svg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        publicURL
        extension
      }
    }
  `)

  return (
    <header>
      <nav>
        <div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="sm:hidden w-16">
              <MobileMenu menu={menu} />
            </div>
            <div className="flex-shrink-0">
              <Link to="/" className="flex items-center">
                <Image {...mark.file} alt="me in emoji form" className="h-10" />
                <span className="sr-only">{siteTitle}</span>
              </Link>
            </div>
            <div className="sm:block hidden">
              <ExpandedMenu menu={menu} />
            </div>
            <div className="w-16">
              <SocialMenu menu={social} />
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}
