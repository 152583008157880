import React from "react"

const Footer = props => {
  const { children } = props
  return (
    <footer>
      <div className="max-w-6xl mx-auto py-5">
        <div className="flex justify-center text-gray-500">{children}</div>
      </div>
    </footer>
  )
}

export default Footer
