import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { CSSTransition } from "react-transition-group"
import { classList } from "../../util/helpers"
import Icon from "../ui/Icon"

export const ExpandedMenu = props => {
  const { menu = {} } = props
  return (
    <nav>
      <ul className="flex items-center space-x-6">
        {Object.values(menu)
          .filter(menuItem => {
            return !menuItem.mobileOnly
          })
          .map((menuItem, idx) => (
            <li key={idx} className="navlink">
              <Link exact to={menuItem.path} activeClassName="text-primary-500">
                {menuItem.name}
              </Link>
            </li>
          ))}
      </ul>
    </nav>
  )
}

export const MobileMenu = props => {
  const { menu = {}, enterDuration = 100, exitDuration = 150 } = props

  const [open, setOpen] = useState(false)

  const menuContainer = useRef()

  useEffect(() => {
    const dropdownListener = e => {
      if (!menuContainer.current || menuContainer.current.contains(e.target)) {
        return
      } else {
        setOpen(false)
      }
    }
    document.addEventListener("mousedown", dropdownListener)
    return () => document.removeEventListener("mousedown", dropdownListener)
  }, [menuContainer])

  return (
    <div ref={menuContainer}>
      <div className="flex items-center">
        <button
          className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-secondary-500 focus:outline-none focus:bg-secondary-700 focus:text-white transition duration-150 ease-in-out z-30"
          onClick={() => setOpen(!open)}
        >
          <svg
            className="h-6 w-6"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              className={open ? "hidden" : "inline-flex"}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
            <path
              className={open ? "inline-flex" : "hidden"}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <CSSTransition
        in={open}
        timeout={{
          enter: enterDuration,
          exit: exitDuration,
        }}
        classNames={{
          enter: "opacity-0 scale-75",
          enterDone: `ease-out duration-100 opacity-100 scale-100`,
          exit: `ease-in duration-150 opacity-0 scale-75`,
        }}
        mountOnEnter
        unmountOnExit
      >
        <div className="absolute -mx-2 top-0 inset-x-0 p-2 left-0 origin-top-left transform transition z-40">
          <div className="rounded-lg shadow-md overflow-hidden">
            <div className="">
              <div className="px-5 py-2 flex items-center bg-white justify-between">
                <div>
                  <button
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-secondary-500 focus:outline-none focus:bg-secondary-700 focus:text-white transition duration-150 ease-in-out z-50 -ml-3"
                    onClick={() => setOpen(!open)}
                  >
                    <svg
                      className="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        className="inline-flex"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3 bg-white">
                {Object.values(menu).map((menuItem, idx) => (
                  <Link
                    key={idx}
                    exact
                    to={menuItem.path}
                    className={classList(
                      "block px-3 py-2 text-base font-medium text-gray-700 hover:text-primary-500 focus:outline-none",
                      idx === 0 && "mt-1"
                    )}
                    activeClassName="text-highlight-yellow text-primary-500"
                  >
                    {menuItem.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

export const SocialMenu = props => {
  const { menu = {} } = props

  return (
    <ul className="flex justify-end space-x-4">
      {Object.values(menu).map((menuItem, idx) => (
        <li key={idx}>
          {menuItem.external ? (
            <a
              className="text-gray-700 hover:text-primary-500 transition ease-in duration-150"
              href={menuItem.path}
              target="_blank"
              rel="noopener noreferrer"
            >
              {menuItem.icon ? (
                <Icon
                  className="fill-current"
                  icon={menuItem.icon.icon}
                  variant={menuItem.icon.type}
                  size="lg"
                />
              ) : (
                menuItem.name
              )}
            </a>
          ) : (
            <Link
              exact
              to={menuItem.path}
              className="text-gray-700 hover:text-primary-500 transition ease-in duration-150"
            >
              {menuItem.name}
            </Link>
          )}
        </li>
      ))}
    </ul>
  )
}
