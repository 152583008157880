import React from "react"
import { library, config } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// icons to import
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons"

import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons"

config.autoAddCss = true

library.add(faQuoteRight, faLinkedin, faGithub)

const Icon = props => {
  const { icon, variant = "fas", ...rest } = props
  return <FontAwesomeIcon icon={[variant, icon]} {...rest} />
}

export default Icon
