import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const Image = props => {
  const {
    alt = "",
    childImageSharp,
    extension,
    publicURL,
    image,
    ...rest
  } = props

  if (!!image && !!image.childImageSharp) {
    return <Img fluid={image.childImageSharp.fluid} alt={alt} {...rest} />
  }

  if (!!childImageSharp) {
    return <Img fluid={childImageSharp.fluid} alt={alt} {...rest} />
  }

  // svg support
  if (!childImageSharp && extension === "svg") {
    return <img src={publicURL} alt={alt} {...rest} />
  }

  if (!!image && typeof image === "string")
    return <img src={image} alt={alt} {...rest} />

  return null
}

Image.propTypes = {
  alt: PropTypes.string,
  childImageSharp: PropTypes.object,
  extension: PropTypes.string,
  publicURL: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default Image
