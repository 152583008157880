export const classList = (...classes) => {
  return classes.filter(item => !!item).join(" ")
}

export const keywords = (edges, target) => {
  const results = {}
  edges.forEach(({ node }) => {
    node.frontmatter[target].forEach(value => {
      if (!results[value]) results[value] = true
    })
  })
  return Object.keys(results)
}

export const stripProtocol = url => {
  return url.replace(/(^\w+:|^)\/\//, "")
}

export const pathParts = path => {
  if (path.startsWith("/")) {
    path = path.slice(1)
  }
  if (path.endsWith("/")) {
    path = path.slice(0, path.length - 1)
  }
  const parts = path.split("/")
  return parts
}

export const pathWalk = parts => {
  if (!parts.length) return []

  const [resource, ...rest] = parts
  let result = [resource]
  pathWalk(rest).forEach(crumb => {
    result.push(`${resource}/${crumb}`)
  })
  return result
}

export const breadcrumbPathGenerator = parts => {
  const crumbs = []
  pathWalk(parts).forEach((part, i) => {
    let p = pathParts(part)
    crumbs[i] = [p[p.length - 1], part]
  })
  return crumbs
}
